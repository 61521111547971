import { GO_TO } from '../actions/navActions'

const findPosition = function (element) {
	var curtop = 0
	if (element.offsetParent) {
		do {
			curtop += element.offsetTop
		} while ((element = element.offsetParent))
	}

	return curtop
}

function scrollMiddleware() {
	return ({ dispatch, getState }) =>
		(next) =>
		async (action) => {
			switch (action.type) {
				case GO_TO:
					if (
						'payload' in action &&
						'location' in action.payload &&
						action.payload.location !== null
					) {
						const type = action.payload.location
						const element = document.querySelector(`[data-block=${type}]`)

						if (element !== null) {
							window.scrollTo({
								top: Math.max(findPosition(element) - 40, 0),
								left: 0,
								behavior: 'smooth',
							})
						}
					} else {
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth',
						})
					}
					break
				default:
					break
			}

			next(action)
		}
}

export default scrollMiddleware()
