import * as React from 'react'
import { Helmet } from 'react-helmet'
import { throttle } from 'lodash'
import { CSSTransition } from 'react-transition-group'
import { useLocation } from '@reach/router'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { goTo } from '../redux/actions/navActions'
import CustomCookieConsent from './CustomCookieConsent'

import { NavLocation, NavAnchors } from '../constants'
import Logo from './Logo'
import NavBar from './NavBar'
import NavBarLocation from './NavBarLocation'
import ContactForm from './ContactForm'

import logo from '../images/kunyit-logo.svg'
import kunyit from '../images/kunyit-min.png'
import landingCutout from '../images/landing-cutout.svg'
import landingCutoutMobile from '../images/landing-cutout-mobile.svg'
import snowball from '../images/snowball.jpg'
import painting from '../images/painting.jpg'
import landing from '../images/landing.jpg'
import headshot from '../images/headshot.jpg'
import '../styles/main.sass'
import Footer from './Footer'

const MobileNavBar = function ({ closeMenu }) {
	return (
		<div className={'mobile-menu-container'}>
			<div className={'mobile-menu-content'}>
				<div className={'logo-container'}>
					<div className={'close-menu'}>
						<button aria-label={'Sluit navigatiemenu'} onClick={closeMenu}>
							<i className={'icon icon-close'}></i>
						</button>
					</div>
					<img src={logo} alt={'Kunyit'} />
				</div>
				<NavBar onClick={closeMenu} />
			</div>
		</div>
	)
}

const StickyNavBar = function ({ toggleMenu }) {
	return (
		<div aria-hidden={true} className={'undocked-menu-container'}>
			<div className={'undocked-menu-content'}>
				<div className={'logo-container'}>
					<Logo />
				</div>
				<div className={'mobile-menu'}>
					<button onClick={toggleMenu}>
						<i className={'icon icon-menu'}></i> Menu
					</button>
				</div>
				<NavBar />
				<div className={'back-to-top'}>
					<NavBarLocation type={null} />
				</div>
			</div>
		</div>
	)
}

const DisconnectedApp = ({ goTo }) => {
	const [isDocked, setDocked] = React.useState(false)
	const [isMenuOpen, setMenuOpen] = React.useState(false)
	const location = useLocation()

	const handleScroll = React.useCallback(() => {
		const shouldDock = window.scrollY > 750
		setDocked(shouldDock)
	}, [])

	React.useEffect(() => {
		handleScroll()

		const throttledHandleScroll = throttle(handleScroll, 250, {
			trailing: false,
		})
		document.addEventListener('scroll', throttledHandleScroll)

		return () => {
			document.removeEventListener('scroll', throttledHandleScroll)
		}
	}, [handleScroll])

	React.useEffect(() => {
		if ('hash' in location && location.hash.length > 0) {
			Object.entries(NavAnchors).forEach((entry) => {
				const [key, hash] = entry

				if (location.hash === `#${hash}`) {
					goTo(key)
				}
			})
		}
	}, [goTo, location])

	const toggleMenu = React.useCallback(() => {
		setMenuOpen(!isMenuOpen)
	}, [isMenuOpen])

	return (
		<main className={isMenuOpen ? 'mobile-menu-open' : ''}>
			<CustomCookieConsent />
			<Helmet
				htmlAttributes={{
					lang: 'nl',
				}}
			>
				<title>Kunyit - Rouwpsycholoog Karin Seydel</title>
				<meta
					name='keywords'
					content='kunyit, koenjit, rouwpsycholoog, karin seydel, rouwverwerking'
				/>
				<meta
					name='description'
					content='Rouwpsycholoog Karin Seydel: koken en samen gesprekken voeren. Welkom aan de oranje eettafel in mijn keuken.'
				/>
				<link rel='canonical' href='https://www.karinseydel.com/' />
				<meta name='twitter:card' content='summary' />
				<meta
					name='twitter:title'
					content='Kunyit - Rouwpsycholoog Karin Seydel'
				/>
				<meta
					name='twitter:description'
					content='Koken en samen gesprekken voeren. Welkom aan de oranje eettafel in mijn keuken.'
				/>
				<meta name='twitter:image' content={landing} />
				<meta property='og:title' content='Kunyit' />
				<meta
					property='og:site_name'
					content='Kunyit - Rouwpsycholoog Karin Seydel'
				/>
				<meta property='og:url' content='https://www.karinseydel.com/' />
				<meta
					property='og:description'
					content='Koken en samen gesprekken voeren. Welkom aan de oranje eettafel in mijn keuken.'
				/>
				<meta property='og:type' content='business.business' />
				<meta property='og:locale' content='nl_NL' />
				<meta property='og:image' content={landing} />
			</Helmet>
			<CSSTransition
				in={isMenuOpen}
				timeout={150}
				unmountOnExit
				classNames='animation'
			>
				<MobileNavBar closeMenu={toggleMenu} />
			</CSSTransition>
			<CSSTransition
				in={isDocked}
				timeout={120}
				unmountOnExit
				classNames='docking'
			>
				<StickyNavBar toggleMenu={toggleMenu} />
			</CSSTransition>
			<div className={'block block-landing'}>
				<div className={'block-content'}>
					<div className={'mobile-menu'}>
						<button aria-label={'Open navigatiemenu'} onClick={toggleMenu}>
							<i className={'icon icon-menu'}></i> Menu
						</button>
					</div>
					<div className={'header'}>
						<div className={'logo-container'}>
							<img src={logo} alt={'Kunyit'} />
							<h2>Karin Seydel</h2>
							<h3>Rouwpsycholoog</h3>
						</div>
						<div className={'menu-container'}>
							<NavBar />
						</div>
					</div>
					<div className={'landing-container'}>
						<div className={'image-container'}>
							<div className={'image'}>
								<div className={'cutout cutout-desktop'}>
									<img src={landingCutout} alt={''} aria-hidden={true} />
								</div>
								<div className={'cutout cutout-mobile'}>
									<img src={landingCutoutMobile} alt={''} aria-hidden={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={'block block-introduction'}>
				<div className={'block-content'}>
					<h1>Welkom aan de oranje eettafel in mijn keuken.</h1>
					<div className={'subcontent'}>
						<div className={'text-container'}>
							<p>
								Aan deze tafel vinden de gesprekken plaats en kunnen we wellicht
								samen een gerecht bereiden waar je een liefdevolle, troostende
								herinnering aan hebt.
							</p>
							<p>
								Als je het fijner vindt om bij jou thuis af te spreken, kom ik
								naar je toe voor gesprekken. En als het er van komt om samen een
								troostgerecht te bereiden, mag dat natuurlijk ook in jouw
								keuken.
							</p>
						</div>
						<div className={'kunyit-container'}>
							<div className={'image-container'}>
								<img src={kunyit} alt={'Kunyit (kurkuma) wortel'} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={'block block-about'}>
				<div className={'background-container'}></div>
				<div className={'block-content'}>
					<div
						className={'text-container white-container'}
						data-block={NavLocation.ABOUT_ME}
					>
						<h1>Kunyit</h1>
						<p>
							Mijn Indische vader maakte thee van <i>kunyit</i>, de
							kurkumawortel, want hij voelde zich niet zo lekker. Hij leerde mij
							dat kunyit een medicijn is.
						</p>
						<p>
							Kunyit is voor mij een metafoor voor het terugvinden van je eigen
							Kracht. Die is er altijd. Maar soms, met name tijdens een
							rouwproces, is deze eigen Kracht hopeloos onvindbaar.
						</p>
						<p className={'emphasis'}>
							Rouw kan een alles overheersend gevoel zijn. Een gevoel dat geen
							uitweg meer lijkt te kennen.
						</p>
						<p>
							Samen maken we deze Kracht weer zachtjes wakker. Dat doen we in
							gesprekken en door het bereiden van een maaltijd waar jij
							bijzondere herinneringen aan hebt. Zo bewandelen we de weg om jouw
							situatie naar het zonlicht te draaien.
						</p>
						<p>
							Rouw gaat nooit weg. Maar je kan wel een manier vinden om het te
							integreren in je leven, zodat je de regie weer terugkrijgt. Dan
							zal er een moment komen dat je weer geniet en ruimte kunt maken
							voor jouw toekomst.
						</p>
					</div>
				</div>
			</div>
			<div className={'block block-food'}>
				<div className={'block-content'}>
					<div className={'image-container'}>
						<div className={'image-ratio'}>
							<img src={painting} alt={'Foto van schilderijen in de keuken'} />
						</div>
					</div>
					<div className={'text-container'}>
						<h3>Samen eten bereiden als onderdeel van het helingsproces.</h3>
						<p>
							Eten waar jij een bijzondere herinnering aan hebt. Eten dat je
							troost biedt. Dat je hoop geeft, juist omdat die ene persoon het
							voor je maakte. Het kan je helpen je innerlijke kracht te voelen
							door samen een gerecht te bereiden.
						</p>
						<div className={'increased-width'}>
							<p className={'quote'}>
								<span>
									“Eten is een geruststelling voor je diepgewortelde identiteit.
								</span>
							</p>
							<br />
							<p className={'quote'}>
								<span>
									Je krijgt een knuffel en zo kun je er weer tegenaan.”
								</span>
							</p>
						</div>
						<p className={'small'}>Fow Pyng Hu, filmmaker en chefkok</p>
					</div>
				</div>
			</div>
			<div className={'block block-story'}>
				<div className={'background-container'}></div>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<div className={'text-container'}>
							<div className={'increased-width'}>
								<p>
									In de jaren zestig viel er altijd een dik pak sneeuw. Als kind
									van vier verwonderde ik me erover dat de klaprozen ondanks die
									sneeuw en ondanks hun teerheid in de lente toch weer omhoog
									kwamen.
									<br />
									Ik pulkte de knoppen open om te kijken waar die groeikracht
									zat.
								</p>
							</div>
							<p className={'large'}>Nergens.</p>
							<p>Dat moest Onzichtbare groeikracht zijn. Voelbaar.</p>
							<p>
								Die kracht hebben we allemaal. Groeikracht. Onzichtbaar, maar
								voelbaar. Die hoop, die kracht van willen leven, nodigen we uit.
								Want daar zit jouw vertrouwen om ondanks wat ook maar, toch
								verder te gaan.
							</p>
						</div>
						<div className={'image-container'}>
							<img src={snowball} alt={'Sneeuwbal'} />
						</div>
					</div>
				</div>
			</div>
			<div className={'block block-information'}>
				<div className={'block-content'}>
					<div
						className={'text-container white-container container-with-photo'}
					>
						<h1 className={'desktop-header'}>Karin Seydel</h1>
						<div className={'subcontent subcontent-about-me'}>
							<div className={'image-container'}>
								<div className={'increased-width'}>
									<div className={'image-ratio'}>
										<img src={headshot} alt={'Karin Seydel, rouwpsycholoog'} />
									</div>
								</div>
							</div>
							<div className={'subcontent-text-container'}>
								<h1 className={'mobile-header'}>Karin Seydel</h1>
								<p>
									Als psycholoog en voormalig uitvaartverzorgster merkte ik hoe
									dierbaar mensen vertelden over de gerechten die de overledene
									altijd maakte. Dat alleen hun maaltijd de lekkerste was.
									Zintuigen en hart werden verwarmd.
								</p>
								<p>
									Toen ik intercultureel emancipatiemedewerkster was, begeleidde
									ik groepen. Geen onderwerp werd besproken zonder iets lekkers
									mee te nemen. Eten verbindt en schept veiligheid om je
									gevoelens te uiten, zoals bij het rouwen.
								</p>
								<p>
									Ik werk als rouwpsycholoog met alle zintuigen. Wat heb jij
									nodig om weer regisseur te worden van jouw leven? Welke
									smaken, welke geuren, welke muziek of geluiden doen jouw hart
									en tranen goed? Wat doet jouw groeikracht weer opbloeien?
									<br />
									Voor mij is dat Kunyit.
								</p>
								<p>
									Ben je benieuwd wat ik voor je zou kunnen doen, neem dan
									contact met me op voor een{' '}
									<Link to='/contact'>vrijblijvend kennismakingsgesprek</Link>.
								</p>
							</div>
						</div>
					</div>
					<div
						className={'subcontent subcontent-rates'}
						data-block={NavLocation.RATES}
					>
						<div className={'subcontent-text-container white-container'}>
							<h2>Tarieven</h2>
							<h3>Individuele therapie</h3>
							<ul>
								<li>Het eerste kennismakingsgesprek is kosteloos.</li>
								<li>
									De vervolgconsulten zijn 90 euro (inclusief btw) voor een uur.
								</li>
							</ul>
							<h3>Reiskosten</h3>
							<p>Voor reiskosten wordt 19 cent per kilometer gerekend.</p>
						</div>
						<div
							className={'subcontent-text-container sidebar white-container'}
						>
							<h2>Meer info?</h2>
							<p>Heb je onbeantwoorde vragen? Neem contact met mij op.</p>
							<span>Telefoonnummer</span>
							<p className={'phone'}>
								<a title={'Bel mij'} href={'tel:31621588511'}>
									06 21 58 85 11
								</a>
							</p>
							<span>Emailadres</span>
							<p className={'email'}>
								<a
									title={'Stuur mij een email'}
									href={'mailto:karin.seydel@kunyit.nl'}
								>
									karin.seydel@kunyit.nl
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className={'block block-contact'} data-block={NavLocation.CONTACT}>
				<div className={'block-content'}>
					<ContactForm />
				</div>
			</div>
			<Footer />
		</main>
	)
}

const App = connect(null, { goTo })(DisconnectedApp)

export default App
