import * as React from 'react'
import { connect } from 'react-redux'
import { goTo } from '../redux/actions/navActions'
import logo from '../images/kunyit-logo.svg'

const DisconnectedLogo = function ({ goTo }) {
	const click = function () {
		goTo(null)
	}

	return (
		<button onClick={click} onKeyDown={click}>
			<img style={{ cursor: 'pointer' }} src={logo} alt={'Kunyit'} />
		</button>
	)
}

const Logo = connect(null, { goTo })(DisconnectedLogo)

export default Logo
