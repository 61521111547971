import {
	DISPLAY_ERROR,
	SUBMIT_FORM,
	SUBMIT_CAPTCHA,
	EMAIL_SENT,
} from '../actions/emailActions'
import { FormState } from '../../constants'

const initialState = {
	formState: FormState.DEFAULT,
	data: {},
	error: null,
}

export default function nav(state = initialState, { type, payload }) {
	switch (type) {
		case DISPLAY_ERROR: {
			return {
				...state,
				formState: FormState.DEFAULT,
				error: payload.message || 'Er is iets fout gegaan. Probeer opnieuw.',
			}
		}
		case SUBMIT_FORM: {
			return {
				...state,
				error: null,
				formState: FormState.WAITING_FOR_CAPTCHA,
				data: { ...payload },
			}
		}
		case SUBMIT_CAPTCHA: {
			return {
				...state,
				error: null,
				formState: FormState.WAITING_FOR_MAILJS,
			}
		}
		case EMAIL_SENT: {
			return {
				...state,
				formState: FormState.MAIL_SENT,
			}
		}
		default:
			return state
	}
}
