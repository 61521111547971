import { SET_NAVBAR_DOCKED } from '../actions/navActions'

const initialState = {
    isDocked: false,
}

export default function nav(state = initialState, { type, payload }) {
    switch (type) {
        case SET_NAVBAR_DOCKED: {
            return {
                ...state,
                isDocked: !!payload.isDocked,
            }
        }
        default:
            return state
    }
}