import * as React from 'react'
import { connect } from 'react-redux'
import { submitForm, submitCaptcha } from '../redux/actions/emailActions'
import { CSSTransition } from 'react-transition-group'
import { FormState } from '../constants'
import ReCAPTCHA from 'react-google-recaptcha'

const DisconnectedContactForm = ({
	error,
	formState,
	isSending,
	submitForm,
	submitCaptcha,
}) => {
	const [name, setName] = React.useState('')
	const [email, setEmail] = React.useState('')
	const [message, setMessage] = React.useState('')
	const captcha = React.useRef()

	const handleSubmit = (event) => {
		event.preventDefault()

		if (formState === FormState.DEFAULT) submitForm({ name, email, message })
	}

	const handleCaptcha = () => {
		const recaptchaValue = captcha.current.getValue()
		submitCaptcha(recaptchaValue)
	}

	return (
		<form onSubmit={handleSubmit} className={isSending ? 'sending-email' : ''}>
			<h1>Neem contact met mij op</h1>
			<CSSTransition
				in={formState === FormState.MAIL_SENT}
				timeout={100}
				unmountOnExit
				classNames='animation'
			>
				<div className={'modal email-sent'}>
					<h2>Bedankt!</h2>
					<div role={'alert'} className={'subcontent-text-container'}>
						<p>
							Dank je wel voor je bericht. Ik zal zo snel mogelijk contact met
							je opnemen.
						</p>
						<p>
							Vriendelijke groet,
							<br />
							Karin Seydel
						</p>
					</div>
				</div>
			</CSSTransition>
			<CSSTransition
				in={formState === FormState.WAITING_FOR_CAPTCHA}
				timeout={100}
				unmountOnExit
				classNames='animation'
			>
				<div role={'alert'} className={'modal captcha'}>
					<div className={'subcontent-text-container'}>
						<p>Om spam te voorkomen, vul alsjeblieft de captcha in.</p>
						<ReCAPTCHA
							ref={captcha}
							sitekey='6LdW83sbAAAAAPtsqhEhM9pw22iQYmkytKQtRqlw'
							onChange={handleCaptcha}
						/>
					</div>
				</div>
			</CSSTransition>
			<div className={'subcontent'}>
				<div>
					<label id={'name-label'} htmlFor={'name'}>
						Naam
					</label>
					<input
						required
						id={'text'}
						placeholder={'Naam'}
						type={'text'}
						aria-required={true}
						aria-labelledby={'name-label'}
						onChange={(event) => setName(event.target.value)}
					/>
				</div>
				<div>
					<label id={'email-label'} htmlFor={'email'}>
						Emailadres
					</label>
					<input
						required
						id={'email'}
						type={'email'}
						aria-required={true}
						placeholder={'jouw@email.com'}
						aria-labelledby={'email-label'}
						onChange={(event) => setEmail(event.target.value)}
					/>
				</div>
			</div>
			<label id={'textarea-label'} htmlFor={'message'}>
				Bericht
			</label>
			<textarea
				id={'message'}
				required
				placeholder={'Waar kan ik je mee helpen?'}
				aria-labelledby={'textarea-label'}
				aria-required={true}
				onChange={(event) => setMessage(event.target.value)}
			/>
			{error !== null ? (
				<div role={'alert'} className={'error'}>
					{error}
				</div>
			) : (
				''
			)}
			<div className={'form-footer'}>
				<div className={'button-container'}>
					{isSending ? (
						<div className={'loader'}>
							<span>
								<i className={'icon icon-loading'}></i>
							</span>
						</div>
					) : (
						''
					)}
					<input type={'submit'} value={'Verstuur bericht'} />
				</div>
			</div>
		</form>
	)
}

const ContactForm = connect(
	(state) => {
		return {
			error: state.email.error,
			formState: state.email.formState,
			isSending: state.email.formState !== FormState.DEFAULT,
		}
	},
	{ submitForm, submitCaptcha }
)(DisconnectedContactForm)

export default ContactForm
