import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
//import { init } from 'emailjs-com'

import App from './App'

export default function Root() {
	React.useEffect(() => {
		if (!('performance' in window)) {
			const offset = Date.now()
			window.performance = {
				now: () => Date.now() - offset,
			}
		}
	})
	return (
		<Provider store={store}>
			<App />
		</Provider>
	)
}
