import { post } from 'axios'
import { goTo } from './navActions'
import { FormState, NavLocation } from '../../constants/'

export const DISPLAY_ERROR = 'DISPLAY_ERROR'
export const EMAIL_SENT = 'EMAIL_SENT'
export const SUBMIT_CAPTCHA = 'SUBMIT_CAPTCHA'
export const SUBMIT_FORM = 'SUBMIT_FORM'

export const displayError = (message) => ({
	type: DISPLAY_ERROR,
	payload: {
		message,
	},
})
export const emailSent = () => ({
	type: EMAIL_SENT,
})

export const _submitForm = ({ name, email, message }) => ({
	type: SUBMIT_FORM,
	payload: {
		name,
		email,
		message,
	},
})

export const _submitCaptcha = () => ({
	type: SUBMIT_CAPTCHA,
})

export const submitForm =
	({ name, email, message }) =>
	(dispatch) => {
		if (typeof name !== 'string' || name.length === 0) {
			dispatch(displayError('Vul alsjeblieft je naam in.'))
		} else if (
			typeof email !== 'string' ||
			email.length === 0 ||
			!/^\S+@\S+$/.test(email)
		) {
			dispatch(displayError('Vul alsjeblieft een geldig emailadres in.'))
		} else if (typeof message !== 'string' || message.length === 0) {
			dispatch(displayError('Vul alsjeblieft een bericht in.'))
		} else {
			// captcha
			dispatch(goTo(NavLocation.CONTACT))
			dispatch(_submitForm({ name, email, message }))
		}
	}

export const submitCaptcha = (response) => (dispatch, getState) => {
	const { email } = getState()

	if (email.formState !== FormState.WAITING_FOR_CAPTCHA) {
		dispatch(
			displayError('Vul alsjeblieft de captcha in om spam te voorkomen.')
		)
	} else {
		dispatch(_submitCaptcha())

		const data = { ...email.data }
		data['g-recaptcha-response'] = response

		post('https://www.karinseydel.com/api/mail.php', data)
			.then(() => {
				dispatch(goTo(NavLocation.CONTACT))
				dispatch(emailSent())
			})
			.catch((error) => {
				if (error.response) {
					// Request made and server responded
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
				} else if (error.request) {
					// The request was made but no response was received
					console.log('No response', error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error during request', error.message)
				}
				dispatch(
					displayError(
						'Er is iets fout gegaan. Probeer opnieuw of stuur alsjeblieft handmatig een email naar karin.seydel@kunyit.nl'
					)
				)
			})

		/*
		send('service_3jmqfwh', 'template_hxicx5f', data).then(
			() => {
				dispatch(goTo(NavLocation.CONTACT))
				dispatch(emailSent())
			},
			(err) => {
				console.log(err)
				dispatch(
					displayError(
						'Er is iets fout gegaan. Probeer opnieuw of stuur handmatig een email naar karinseydel@gmail.com.'
					)
				)
			}
		)*/
	}
}
