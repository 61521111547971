import { createStore, applyMiddleware } from 'redux'
//import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

import scrollMiddleware from './middleware/scrollMiddleware'

export default createStore(
	rootReducer,
	{},
	applyMiddleware(thunk, scrollMiddleware)
)

/*
export default function configureStore() {
    return createStore(
        rootReducer,
        {}, // preload
        compose(
            applyMiddleware(...[thunkMiddleware ]),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    )
}*/
